import React, { PropsWithChildren } from "react"

import { Text, TextProps } from "@chakra-ui/layout"
import { Box, Link } from "@chakra-ui/react"

import { menuItem } from "./styles.module.css"

interface MenuItemProps extends TextProps {
  to: string
}

export const MenuItem = ({
  children,
  to = "/",
  onClick,
  ...rest
}: PropsWithChildren<MenuItemProps>) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom={["1px solid", "none"]}
      flex={1}
      flexShrink={0}
      justifyContent="center"
      className={menuItem}
      textAlign="center"
      _first={{
        borderTop: ["1px solid", "none"],
      }}
    >
      <Link href={to} width="100%" height="100%">
        <Text display="block" {...rest} onClick={onClick} py={4} px={4}>
          {children}
        </Text>
      </Link>
    </Box>
  )
}
