import React from "react"

import { Box } from "@chakra-ui/layout"
import { Flex, Heading, Stack, Text } from "@chakra-ui/react"

import { Image } from "../Image"
import { PageSection } from "../page-section"

export const TheParty = (): JSX.Element => {
  return (
    <PageSection id="the-party">
      <Flex flexDirection={["column", "row"]}>
        <Box flex={1} borderRight={["none", "1px solid"]}>
          <Image name="windyCuddle" />
        </Box>
        <Flex
          flex={1}
          flexGrow={0}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
          textAlign="left"
          py={["72px", "auto"]}
        >
          <Stack spacing={12} px={["24px", "50px"]} mb={["auto", "72px"]}>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Ceremony
              </Heading>
              <Text>Near the fountain</Text>
              <Text> Castlemaine Botanical Gardens</Text>
              <Text> Arrive at 3pm for a 3:30pm start</Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                The in-between
              </Heading>
              <Text>Enjoy a wine or beer in the garden on us.</Text>
              <Text listStylePos="inside">
                Or you can grab a drink nearby at:
                <Text as="ul">
                  <li>The Bridge Hotel</li>
                  <li>Shedshaker Brewery</li>
                </Text>
              </Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Reception
              </Heading>
              <Text>Boomtown Winery</Text>
              <Text>Wine &amp; good times from 5pm</Text>
            </Box>
            <Box>
              <Heading as="h3" mb={3} fontSize="32px">
                Recovery brunch
              </Heading>
              <Text>The Railway Hotel — Monday from 2pm</Text>
              <Text>Feel free to join us the next day for a recovery beer and some snacks in the beer garden</Text>
            </Box>
          </Stack>
        </Flex>
      </Flex>
    </PageSection>
  )
}
