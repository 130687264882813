import React from "react"

import { Box } from "@chakra-ui/react"

import { Details } from "../components/details"
import { HeroBanner } from "../components/hero-banner"
import { NavBar } from "../components/nav-bar"
import { Rsvp } from "../components/rsvp"
import SEO from "../components/seo"
import { TheCouple } from "../components/the-couple"
import { TheParty } from "../components/the-party"
import { TheTown } from "../components/the-town"

const IndexPage = () => (
  <Box id="top">
    <SEO />
    <HeroBanner />
    <NavBar />
    <TheParty />
    <TheTown />
    <TheCouple />
    <Rsvp />
    <Details />
  </Box>
)

export default IndexPage
