import React, { PropsWithChildren } from "react"

import { Heading } from "@chakra-ui/layout"
import { Box, BoxProps, Flex, Link, Stack, Text } from "@chakra-ui/react"

import { PageSection } from "../page-section"

interface DetailsSectionProps extends BoxProps {
  heading: string
}

const DetailsSection = ({
  heading,
  children,
  ...rest
}: PropsWithChildren<DetailsSectionProps>) => {
  return (
    <Box
      px={["24px", "90px"]}
      py="72px"
      flex={1}
      borderBottom={["1px solid", "none"]}
      {...rest}
    >
      <Stack spacing={4}>
        <Heading as="h3">{heading}</Heading>
        {children}
      </Stack>
    </Box>
  )
}

export const Details = (): JSX.Element => {
  return (
    <PageSection id="details" borderBottom="none">
      <Flex width="100%" flexDirection={["column", "row"]}>
        <DetailsSection
          heading="Wishing well"
          borderRight={["none", "1px solid"]}
        >
          <Text>
            A monetary contribution towards decorating our new home would be
            greatly appreciated.
          </Text>
        </DetailsSection>
        <DetailsSection heading="Dress code">
          <Text>
            Dress like you’re going to a wedding. Semi-formal, that kinda thing.
          </Text>
        </DetailsSection>

        <DetailsSection heading="Tunes" borderLeft={["none", "1px solid"]}>
          <Text>
            Get ready for the dance floor with some of our fave songs!
          </Text>
          <Text>(Coming soon)</Text>
          {/* <Link textDecoration="underline" fontSize="18px" href={"#"}> */}
          {/* Link to playlist */}
          {/* </Link> */}
        </DetailsSection>
      </Flex>
    </PageSection>
  )
}
