import React from "react"

import { Box } from "@chakra-ui/layout"
import { Flex, Heading, Stack, Text } from "@chakra-ui/react"

import { Image } from "../Image"
import { PageSection } from "../page-section"

export const TheCouple = (): JSX.Element => {
  return (
    <PageSection id="the-couple">
      <Flex flexDirection={["column", "row"]} flexShrink={0}>
        <Box flex={1} borderRight={["none", "1px solid"]}>
          <Image name="kisses" />
        </Box>
        <Flex
          flex={1}
          flexGrow={0}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
          textAlign="left"
          py={["72px", "auto"]}
        >
          <Box
            pl={["24px", "50px"]}
            mb={["auto", "72px"]}
            pr={["24px", "100px"]}
          >
            <Heading as="h3" mb={3} fontSize="32px">
              Claire &amp; Isaac
            </Heading>
            <Stack spacing={4}>
              <Text>
                We met 8 years ago, when Isaac started working at Papercloud. We
                bonded over our similar music tastes &amp; emo phases, and
                playing Halo online together into the wee hours of the night.
              </Text>
              <Text>
                7 years later, we were wandering the side streets of Shibuya on
                our second trip to Japan. We tried to go to our favourite wine
                bar but it was closed. Making the most of the situation, Claire
                asked Isaac to pose for a photo opp. And Isaac, making the most
                of the moment too, pulled a ring out of his bag…
              </Text>
            </Stack>
          </Box>
        </Flex>
      </Flex>
    </PageSection>
  )
}
