import React from "react"

import { Box } from "@chakra-ui/layout"
import { Flex, Heading, Link, Stack, Text } from "@chakra-ui/react"

import { Image } from "../Image"
import { PageSection } from "../page-section"

const GOOGLE_MAPS_URL = "https://goo.gl/maps/icMHai7V3D975yRj7"

export const TheTown = () => {
  return (
    <PageSection id="the-town">
      <Flex flexDirection={["column-reverse", "row"]} flexShrink={0}>
        <Flex
          flex={1}
          flexGrow={0}
          flexDirection="column"
          justifyContent={["center", "flex-end"]}
          textAlign="left"
          py="72px"
          the
        >
          <Stack spacing={2} px={["24px", "50px"]} mb={["auto", "72px"]}>
            <Heading as="h3" mb={3} fontSize="32px">
              Castlemaine
            </Heading>
            <Text fontSize="18px">
              A small, historic town with a lot of great food, wine, and art.
              We’ve collated a list of places to eat &amp; visit so you can
              enjoy all that Castlemaine has to offer.
            </Text>
            <Link
              textDecoration="underline"
              fontSize="18px"
              href={GOOGLE_MAPS_URL}
            >
              View list
            </Link>
          </Stack>
        </Flex>
        <Box flex={1}>
          <Image name="map" />
        </Box>
      </Flex>
    </PageSection>
  )
}
