import React, { useState } from "react"

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons"
import { Box, Flex, StackProps } from "@chakra-ui/layout"
import { IconButton, Link } from "@chakra-ui/react"

import { RsvpButton } from "../rsvp/rsvp-button"
import { MenuItem } from "./menu-item"
import styles from "./styles.module.css"

export const RSVP_URL = "https://airtable.com/shrM86uFGa1IZDB29"

interface NavLinkProps {
  to: string
  label: string
}

const navLinks: Array<NavLinkProps> = [
  {
    to: "#the-party",
    label: "The party",
  },
  {
    to: "#the-town",
    label: "The town",
  },
  {
    to: "#the-couple",
    label: "The couple",
  },
  {
    to: RSVP_URL,
    label: "RSVP",
  },
]

const MenuItems = ({
  onClick,
  items,
  ...rest
}: { items: Array<NavLinkProps> } & StackProps): JSX.Element => {
  return (
    <Box
      as={"nav"}
      display="flex"
      width="100%"
      flexDirection="row"
      justifyContent="center"
      {...rest}
    >
      {items.map(({ label, to }, i) => {
        return (
          <MenuItem key={i} to={to} onClick={onClick}>
            {label}
          </MenuItem>
        )
      })}
    </Box>
  )
}

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <Box className={styles.navBar}>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: !isOpen ? "none" : "inherit" }}
          onClick={toggleOpen}
          className={styles.menuButton}
        />
        <Flex alignItems="center" justifyContent="space-evenly" width="100%">
          <MenuItems display={{ base: "none", md: "flex" }} items={navLinks} />
          <Box
            display={["flex", "none"]}
            width="100%"
            justifyContent="flex-end"
          >
            <RsvpButton height="56px" width="134px" fontSize="20px" />
          </Box>
        </Flex>
      </Flex>

      <Box
        className={styles.mobileMenu}
        height={isOpen ? (navLinks.length - 1) * 64 : 0}
      >
        <MenuItems
          flexDirection="column"
          onClick={() => setIsOpen(false)}
          items={navLinks.slice(0, -1)}
        />
      </Box>
    </Box>
  )
}
