import React from "react"

import { Button, ButtonProps, Link } from "@chakra-ui/react"

import { RSVP_URL } from "../nav-bar/nav-bar"
import styles from "./styles.module.css"

export const RsvpButton = (props: ButtonProps) => {
  return (
    <Link href={RSVP_URL}>
      <Button
        className={styles.rsvpButton}
        borderRadius={0}
        px={["48px", "72px"]}
        py="20px"
        height="auto"
        fontSize={["24px", "32px"]}
        {...props}
      >
        RSVP
      </Button>
    </Link>
  )
}
