import React from "react"

import { Heading } from "@chakra-ui/layout"
import { Stack } from "@chakra-ui/react"

import Protea from "../../assets/Protea.svg"
import { PageSection } from "../page-section"
import { RsvpButton } from "./rsvp-button"
import styles from "./styles.module.css"

export const Rsvp = (): JSX.Element => {
  return (
    <PageSection
      id="rsvp"
      position="relative"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={625 + 72}
      px={["24px", "48px"]}
      pt="72px"
      overflow="hidden"
    >
      <Protea className={styles.protea} top="20px" />
      <Stack spacing={4} alignItems="center" zIndex={2} textAlign="center">
        <Heading
          as="h3"
          className={styles.rsvpHeading}
          fontSize={["36px", "48px"]}
          zIndex={2}
          mb={["20px", "48px"]}
          maxW="700px"
        >
          We can’t wait for you to join us on our special day
        </Heading>
        <RsvpButton />
      </Stack>
    </PageSection>
  )
}
